// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

* {
	margin: 0;
	padding: 0;
}

body {
	position: relative;
	
	font-family: $font-stack;
	font-weight: 400;
	font-style: normal;
	color: $black-color;
	-webkit-font-smoothing: antialiased;
	/*-webkit-font-smoothing: subpixel-antialiased;*/
	/*-moz-osx-font-smoothing: auto;*/
	-webkit-overflow-scrolling: touch;

	background: #f5f7f9;
	overflow-x: hidden;
}

// -----------------------------------------------------------------------------
// Animation.
// -----------------------------------------------------------------------------

.loading {
	animation-name: rotate;
	animation-duration: .75s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes rotate {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}

@keyframes opacityChange {
    0% {opacity: 1}
    50% {opacity: .5}
    100% {opacity: 1}
}

// -----------------------------------------------------------------------------
// Block transition.
// -----------------------------------------------------------------------------

@include phone() {
	*[class*="transition-"]:after {
		display: none;
	}
}

.transition-bottom:after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(to top, #fff, transparent);
}

.transition-top:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(to bottom, #fff, transparent);
}

.transition-left:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100px;
	height: 100%;
	background: linear-gradient(to right, #fff, transparent);
}

.transition-right:after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	width: 100px;
	height: 100%;
	background: linear-gradient(to left, #fff, transparent);
}

.transition-dark-bottom:after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(to top, #1d1d1d, transparent);
}

.transition-dark-top:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(to bottom, #1d1d1d, transparent);
}

.transition-dark-left:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100px;
	height: 100%;
	background: linear-gradient(to right, #1d1d1d, transparent);
}

.transition-dark-right:after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	width: 100px;
	height: 100%;
	background: linear-gradient(to left, #1d1d1d, transparent);
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 6px;
	background: rgba(255,255,255,.85);
	text-align: center;
	z-index: 3;
	
	&-text {
		color: $primary-color;
		font-size: 12px;
		font-weight: 500;
		animation-name: opacityChange;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	i {
		font-size: 32px;
		color: $primary-color;
		margin-bottom: 5px;
	}
}

// -----------------------------------------------------------------------------
// Background fill.
// -----------------------------------------------------------------------------

.bg-primary {
	background: $primary-color !important;
}

.bg-secondary {
	background: $gray-color !important;
}

.bg-success {
	background: $green-color !important;
}

.bg-warning {
	background: $orange-color !important;
}

.bg-danger {
	background: $red-color !important;
}

.bg-info {
	background: $light-blue-color !important;
}

.bg-light {
	background: $light-gray-color !important;
}

.bg-light-blue {
	background: #f5f9ff !important;
}

.bg-dark {
	background: $black-color !important;
}

.bg-white {
	background: #fff !important;
}

// -----------------------------------------------------------------------------
// Blox aligment (if element have parent flex element).
// -----------------------------------------------------------------------------

.pull-right {
	margin-left: auto !important;
}

.pull-left {
	margin-right: auto !important;
}

// -----------------------------------------------------------------------------
// CSS modifiers.
// -----------------------------------------------------------------------------

@for $i from 0 through 20 {
  	.mt-#{$i * 5} { 
  		margin-top: $i * 5px !important;
  	}
}

@for $i from 0 through 20 {
  	.mb-#{$i * 5} { 
  		margin-bottom: $i * 5px !important;
  	}
}

@for $i from 0 through 20 {
  	.mr-#{$i * 5} { 
  		margin-right: $i * 5px !important;
  	}
}

@for $i from 0 through 20 {
  	.ml-#{$i * 5} { 
  		margin-left: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.my-#{$i * 5} { 
  		margin-top: $i * 5px !important;
  		margin-bottom: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.mx-#{$i * 5} { 
  		margin-left: $i * 5px !important;
  		margin-right: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.py-#{$i * 5} { 
  		padding-top: $i * 5px !important;
  		padding-bottom: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.px-#{$i * 5} { 
  		padding-left: $i * 5px !important;
  		padding-right: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.pt-#{$i * 5} { 
  		padding-top: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.pb-#{$i * 5} { 
  		padding-bottom: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.pl-#{$i * 5} { 
  		padding-left: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.pr-#{$i * 5} { 
  		padding-right: $i * 5px !important;
  	}
}

@for $i from 0 through 25 {
  	.pb-#{$i * 5} { 
  		padding-bottom: $i * 5px !important;
  	}
}

.border-right {
	border-right: $block-border !important;
}

.border-left {
	border-left: $block-border !important;
}

.border-top {
	border-top: $block-border !important;
}

.border-bottom {
	border-bottom: $block-border !important;
}

.absolute {
	position: absolute;
}

.relative {
	position: relative;
}

.hidden {
	display: none;
}

// -----------------------------------------------------------------------------
// Other.
// -----------------------------------------------------------------------------

.container-fluid {
	padding-left: 25px;
	padding-right: 25px;

	@include phone() {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.row-bordered {
	& > div[class*="col-"]:not(.col-12),
	& > div[class*="col-"]:not(.col-sm-12),
	& > div[class*="col-"]:not(.col-md-12),
	& > div[class*="col-"]:not(.col-lg-12), 
	& > div[class*="col-"]:not(.col-xl-12) {

		&:not(:last-child) {
			border-right: $block-border;
		}
	}
	@include desktop-small() {
		& > div[class*="col-xl"] {
			border-right: 0;
		}
	}

	@include tablet() {
		& > div[class*="col-xl"],
		& > div[class*="col-lg"] {
			border-right: 0;
		}
	}

	@include phone() {
		& > div[class*="col-xl"],
		& > div[class*="col-lg"],
		& > div[class*="col-md"] {
			border-right: 0;
		}
	}
}