// -----------------------------------------------------------------------------
// This file contains payment icons styles.
// 
// Used in: public/component-icons.html
// -----------------------------------------------------------------------------

$payments-icons: ('2checkout-dark', '2checkout', 'alipay-dark', 'alipay', 'amazon-dark', 'amazon', 'americanexpress-dark', 'americanexpress', 'applepay-dark', 'applepay', 'bancontact-dark', 'bancontact', 'bitcoin-dark', 'bitcoin', 'bitpay-dark', 'bitpay', 'cirrus-dark', 'cirrus', 'clickandbuy-dark', 'clickandbuy', 'coinkite-dark', 'coinkite', 'dinersclub-dark', 'dinersclub', 'directdebit-dark', 'directdebit', 'discover-dark', 'discover', 'dwolla-dark', 'dwolla', 'ebay-dark', 'ebay', 'eway-dark', 'eway', 'giropay-dark', 'giropay', 'googlewallet-dark', 'googlewallet', 'ingenico-dark', 'ingenico', 'jcb-dark', 'jcb', 'klarna-dark', 'klarna', 'laser-dark', 'laser', 'maestro-dark', 'maestro', 'mastercard-dark', 'mastercard', 'monero-dark', 'monero', 'neteller-dark', 'neteller', 'ogone-dark', 'ogone', 'okpay-dark', 'okpay', 'paybox-dark', 'paybox', 'paymill-dark', 'paymill', 'payone-dark', 'payone', 'payoneer-dark', 'payoneer', 'paypal-dark', 'paypal', 'paysafecard-dark', 'paysafecard', 'payu-dark', 'payu', 'payza-dark', 'payza', 'ripple-dark', 'ripple', 'sage-dark', 'sage', 'sepa-dark', 'sepa', 'shopify-dark', 'shopify', 'skrill-dark', 'skrill', 'solo-dark', 'solo', 'square-dark', 'square', 'stripe-dark', 'stripe', 'switch-dark', 'switch', 'ukash-dark', 'ukash', 'unionpay-dark', 'unionpay', 'verifone-dark', 'verifone', 'verisign-dark', 'verisign', 'visa-dark', 'visa', 'webmoney-dark', 'webmoney', 'westernunion-dark', 'westernunion', 'worldpay-dark', 'worldpay');

.payments {
	width: 40px;
	height: 25px;
	display: inline-block;
	background: no-repeat center/100% 100%;
	vertical-align: bottom;
	font-style: normal;
	border: 1px solid #eee;
	border-radius: 3px;

}

@each $payment in $payments-icons {
	.payments-#{$payment} {
		background-image: url('../../images/payments/' + $payment + '.svg');
	}
}