// -----------------------------------------------------------------------------
// This file contains footer block styles.
// -----------------------------------------------------------------------------

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 20px;
	background: #fff;
	font-size: 14px;
	color: $gray-color;
	border-top: 1px solid #eee;

	@include phone() {
		padding: 12px 0;
		flex-direction: column;

		& > *:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li,
	a,
	button {
		font-size: 14px;
	}

	p {
		margin: 0;
	}

	ul {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;

		& > * {
			margin-left: 10px;
			margin-bottom: 0;
		}

		a {
			font-size: 14px;
		}
	}

	.row {
		align-items: center;
	}
}