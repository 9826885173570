// -----------------------------------------------------------------------------
// This file contains dropdown styles.
// 
// Used in: public/dashboard-1.html
// -----------------------------------------------------------------------------

.dropdown {
	position: relative;
	
	&-menu {
		@include block-style();
		display: none;
		margin-top: 3px;
		padding: 3px 0px;
		list-style: none;
		z-index: 3;

		@include phone(){
			max-width: 280px;
		}

		li {
			margin: 0;
		}
	}

	&-item {
		display: block;
		min-width: 90px;
		padding: 7px 18px;
		background: #fff;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			background: lighten($primary-color, 37.5%);
		}
	}

	&-divider{
		position: relative;
		background: #fff;
		padding: 5px 0;

		&:after {
			position: absolute;
			top: 50%;
			margin-top: -1px;
			width: 100%;
			height: 1px;
			content: '';
			background: $light-gray-color;
		}
	}

	&-toggle:after {
	    display: inline-block;
	    width: 0;
	    height: 0;
	    margin-left: 5px;
	    vertical-align: 2px;
	    content: "";
	    border-top: 4px solid;
	    border-right: 4px solid transparent;
	    border-bottom: 0;
	    border-left: 4px solid transparent;
	}
}