// -----------------------------------------------------------------------------
// This file contains breadcrumbs block styles.
// 
// Used in: public/dashboard-1.html
// -----------------------------------------------------------------------------

.breadcrumb {
	margin: 5px 0;
	padding: 0;

	&-item {
		position: relative;
		display: inline-block;
		margin-right: 3px;
		font-size: 14px;
		color: $gray-color;

		a {
			color: $primary-color
		}

		& + .breadcrumb-item:before {
			content: '/';
			margin-right: 5px;
		}
	}
}