// -----------------------------------------------------------------------------
// This file contains loader styles.
// 
// Used in: public/component-widgets.html
// -----------------------------------------------------------------------------

.loader {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 20;
	font-size: 64px;
}