// -----------------------------------------------------------------------------
// This file contains chat blocks styles.
// 
// Used in: public/page-chat.html
// -----------------------------------------------------------------------------

.chat-column {
	width: 40%;
	border-right: $block-border;

	& + .chat-content {
		width: 60%;
	}

	@include phone() {
		width: 100%;

		& + .chat-content {
			width: 100%;
		}
	}

	.user-panel {
		padding: 15px 25px;
		padding-right: 15px;
		cursor: pointer;

		&:hover {
			background: rgba(0,0,0,0.025);
		}

		& + .user-panel {
			margin: 0;
		}

		&:after {
			position: absolute;
			content: '';
			bottom: 0;
			right: 0;
			width: 70%;
			height: 1px;
			background: #eee;
		}

		&.active {
			border-left: 3px solid $primary-color;
		}
	}
}

.chat-list {
	max-height: 76vh;
	overflow-y: scroll;
}

.chat-search {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;

	&:before {
		position: absolute;
		content: '';
		top: 100%;
		left: 0;
		width: 100%;
		height: 10px;
		background: linear-gradient(to bottom, #fff, transparent);
		z-index: 10;
	}

	i {
		color: $gray-color;
		opacity: .35;
	}

	input {
		padding: 21px 15px;
		font-size: 14px;
		border: 0;
		margin-right: auto;
		width: 100%;

		&:focus {
			outline: none;
		}
	}
}


.chat-content {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
}

.chat-header {
	display: flex;
	align-items: center;
	padding: 0px 25px;
	height: 80px;
	border-bottom: $block-border;

	.avatar {
		margin-right: 15px;
	}

	small {
		display: block;
		font-weight: 400;
	}
}

.chat-messages {
	height: 100%;
	padding: 20px;
	height: 100%;
	max-height: 100%;
	background-color: $light-gray-color;
	overflow-y: scroll;

	@include phone() {
		max-height: 400px;
	}
}

.chat-footer {
	padding: 15px;
}