// -----------------------------------------------------------------------------
// This file contains event column styles.
// 
// Used in: public/component-events.html
// -----------------------------------------------------------------------------

.event-column {
	position: relative;
	display: flex;
	padding-left: 20px;
	font-size: 14px;
	text-align: left;

	a {
		color: $primary-color;
		font-weight: 500;
	}

	&:not(:last-child) {
		padding-bottom: 20px;
	}

	&:not(:last-child):before {
		position: absolute;
		left: 3px;
		top: 5px;
		content: '';
		width: 2px;
		height: 100%;
		background: $light-gray-color;
	}

	&:after {
		position: absolute;
		content: '';
		top: 5px;
		left: 0;
		width: 8px;
		height: 8px;
		border-radius: 150px;
		background: $gray-color;
		z-index: 3;
	}

	&-primary:after {
		background: $primary-color;
	}

	&-secondary:after {
		background: $gray-color;
	}

	&-success:after {
		background: $green-color;
	}

	&-warning:after {
		background: $orange-color;
	}

	&-danger:after {
		background: $red-color;
	}

	&-info:after {
		background: $light-blue-color;
	}

	&-light:after {
		background: $light-gray-color;
	}

	&-dark:after {
		background: $black-color;
	}

	& > .avatar {
		margin-top: 4px;
		margin-right: 10px;
	}

	& > .avatar-sm {
		@include phone() {
			min-width: 40px;
			min-height: 40px;
		}
	}

	.event-content {
		max-width: 80%;
		margin-right: 5px;

		& > *:not(:last-child){
			margin-bottom: 3px;
		}

		.box {
			display: table;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.box:nth-child(1) ~ .box {
			margin-left: 20px;
		}

		p {
			margin: 0;
		}
	}

	&-image {
		padding-left: 0;
		&:after {
			display: none;
		}

		&:not(:last-child):before {
			left: 20px;
			background: #e6ecf1;
		}

		.avatar {
			width: 40px;
			height: 40px;
			border: 3px solid #e6ecf1;
		}

		& > .avatar {
			margin-right: 16px;
		}
	}
}




.event-image-column {

}