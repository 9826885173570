// -----------------------------------------------------------------------------
// This file contains user panel styles.
// 
// Used in: public/component-user-panel.html
// -----------------------------------------------------------------------------

.user-panel {
	position: relative;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
	z-index: 3;
	
	&-image {
		margin-right: 12px;
	}

	&-info {
		line-height: 20px;

		p,
		small {
			color: inherit;
		}

		& + .btn-toolbar {
			margin-left: 17px;
		}
	}

	&-actions {
		display: flex;
		margin-left: auto;
		color: $primary-color;

		small {
			margin: 0px 12px;
		}

		a:not(.dropdown-item),
		button:not(.dropdown-item) {
			display: block;
			font-size: 14px;
			margin: 0px 5px;
			color: $primary-color;

			&:hover,
			&:active,
			&:focus {
				opacity: .5;
				text-decoration: none;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
		
	small {
		display: block;
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}

	p {
		margin: 0;

		&:not(:first-child) {
			font-weight: 400;
		}
	}

	.badge {
		margin-left: 5px;
	}

	&-column {
		flex-direction: column;
		text-align: center;

		.user-panel-image {
			margin-right: 0;
			margin-bottom: 8px;
		}

		.user-panel-actions {
			margin-top: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	& + & {
		margin-top: 16px;
	}

	&-lg {
		.user-panel-info {
			p {
				font-weight: 300;
				font-size: 22px;
				& + * {
					margin-top: 7px;
				}
			}

			small {
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
}

a.user-panel,
button.user-panel {
	&:hover {
		opacity: .8;
	}
}