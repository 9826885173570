// -----------------------------------------------------------------------------
// This file contains popover block styles.
// 
// Used in: public/component-buttons.html
// -----------------------------------------------------------------------------

.popover {
	display: none;
	position: absolute;
	margin: 8px;
	min-width: 150px;
	max-width: 320px;
	border-radius: $block-border-radius;
	border: $block-border;
	background: #fff;
	box-shadow: 0px 5px 20px rgba(0,0,0,.075);
	font-size: 13px;
	z-index: 3;

	&:after {
		position: absolute;
		content: '';
		left: 50%;
		top: 101%;
		height: 8px;
		width: 8px;
		background-color: #fff;
		border-bottom: $block-border;
		border-left: $block-border;
		transform: translateY(-50%) rotate(-45deg);
		z-index: 5;
	}

	&[x-placement="left"]:after{
		left: 99%;
		top: 50%;
		transform: translateY(-50%) rotate(-135deg);
	}

	&[x-placement="bottom"]:after{
		left: 50%;
		top: 0;
		background: #f2f2f2;
		transform: translateY(-50%) rotate(-225deg);
	}

	&[x-placement="right"]:after{
		left: -5px;
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 15px;
		background: darken(#fff, 5%);
		border-bottom: $block-border;
		color: $gray-color;

		.btn {
			padding: 0;
			color: rgba(0,0,0,.25);
		}
	}

	&-body {
		padding: 8px 15px;
	}
}